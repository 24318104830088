@font-face {
  font-family: 'He\'s Dead Jim';
  src: url('HesDeadJim.woff2') format('woff2'),
  url('HesDeadJim.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@700&display=swap');

body {
  background: transparent;
}

#root {

  overflow: hidden;
}

.App {
  /*font-family: 'He\'s Dead Jim';*/
  font-family: 'Work Sans', sans-serif;
  font-size: 31px;
  text-align: center;
  /*text-transform: capitalize;*/
  /*font-style: italic;*/
}

.EditApp {
  padding: 20px;
}

.top-bar {
  display: flex;
  margin-top: 40px;
  color: #fff;
}

.bar-home,
.bar-visitor {
  display: flex;
  width: 50%;
  height: 70px;
}

.bar-home{
  padding-right: 5px;
  display: flex;
  flex-wrap: wrap;
}
.bar-visitor{
  padding-left: 5px;
  display: flex;
  flex-wrap: wrap;
}

.bar-home__penalty {
  display: flex;
  width: 100%;
  padding-right: 127px;
  justify-content: flex-end;
}

.bar-visitor__penalty {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  width: 100%;
  padding-left: 85px;
}

.bar-home__penalty-item,
.bar-visitor__penalty-item {
  position: relative;
  margin: 8px 5px 0;
  padding: 5px 0;
  width: 120px;
  font-size: 70%;
}

.bar-home__penalty-item::before {
  /*background: rgba(29, 66, 138, 0.5);*/
  background: rgba(00, 128, 255, 0.5);
}

.bar-visitor__penalty-item::before {
  /*background: rgba(166, 25, 46, 0.5);*/
  background: rgba(230, 0, 0, 0.5);
}

.bar-home__score,
.bar-visitor__score,
.bar-home__team,
.bar-visitor__team {
  padding: 16px 0;
}

.bar-home__team,
.bar-visitor__team {
  padding-right: 60px;
  padding-left: 60px;
}

.bar-home__penalty-item::before,
.bar-visitor__penalty-item::before,
.bar-home__score::before,
.bar-visitor__score::before,
.bar-home__team::before,
.bar-visitor__team::before,
.time-bar__period::before,
.time-bar__time::before,
.banner__top::before,
.banner__bottom::before {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  content: ' ';
  transform: skew(-20deg);
  z-index: -1;
}

.bar-home__team {
  position: relative;
  width: 100%;
  text-align: right;
}
.bar-home__team::before {
  background: rgba(00, 128, 255, 0.7);
  width: 200%;
  right: -1px;
}


.bar-home__score {
  position: relative;
  width: 150px;
}
.bar-home__score::before {
  background: rgba(00, 128, 255, 0.5);
}

.home__penalty {
  width: 100%;
}

.bar-visitor__team {
  position: relative;
  width: 100%;
  text-align: left;
}
.bar-visitor__team::before {
  background: rgba(230, 0, 0, 0.7);
  width: 200%;
  right: inherit;
  left: 0;
}

.bar-visitor__score {
  position: relative;
  width: 150px;
}
.bar-visitor__score::before {
  background: rgba(230, 0, 0, 0.5);
  right: inherit;
  left: 0;
}

.time-bar {
  display: flex;
  width: 100%;
  height: 70px;
  /*margin-top: 10px;*/
  color: #fff;
  font-size: 90%;
}

.time-bar__time {
  box-sizing: border-box;
  position: relative;
  width: 195px;
  height: 100%;
  padding: 18px 0;
}

.time-bar__time::before {
  width: 200%;
  background: rgba(207, 205, 201, 0.5);
}

.time-bar__period {
  display: block;
  box-sizing: border-box;
  position: relative;
  width: 80px;
  height: 100%;
  padding: 18px 0;
}

.time-bar__period::before {
  background: rgba(207, 205, 201, 0.4);
}

.banner {
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 1000px;
  line-height: 70px;
  text-align: left;
  color: #fff;
  font-size: 30px;
  font-family: 'Work Sans', sans-serif;
  text-transform: uppercase;
}

.banner__top,
.banner__bottom {
  display: flex;
  position: relative;
  height: 70px;
  padding: 0 30px;
}

.banner__top--title {
  padding-left: 20px;
}

.banner__bottom--push-right {
  margin-left: 90px;
}

.banner__top::before {
  background: rgba(207, 205, 201, 0.6);
}

.banner__bottom::before {
  background: rgba(207, 205, 201, 0.4);
}

.banner__bottom--left,
.banner__bottom--right {
  width: 50%;
}
